export const productKitArray = [
  {
    id: 'face-cream-face-wash',
    name: 'Face Cream & Face Wash For Skin Brightening',
    link: 'https://www.vasustore.com/r-g-face-cream-face-wash-for-skin-brightening',
    tag: '',
    size: '(50ml/ 1.69 FL.OZ) (75ml/ 2.54 FL.OZ)',
    price: 524,
    mrp: 748,
    smallDesc: '',
    img: '/images/products/face-cream-face-wash.jpg',
    media: [
      { id: 0, value: '/images/products/face-cream-face-wash.jpg' },
      { id: 1, value: '/images/products/1080x1080/cream/01.png' },
      { id: 2, value: '/images/products/1080x1080/cream/03.jpg' },
      { id: 3, value: '/images/products/face-wash.png' },
      { id: 4, value: '/images/products/1080x1080/face-wash/02.jpg' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>A rich combination of <span class="text-customColors-300">clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters,</span> which helps reduce hyper-pigmentation & dark spots and evens out skin tone while protecting from harmful UV radiation, for a visibly brighter and youthful appearance.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Prevent pigmentation</li>' +
          '<li>Protect skin from harmful UV radiation</li>' +
          '<li>To make skin radiant & healthy</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">3-O-Ethyl ascorbic acid</span>- Helps in Skin Lightening and reduces skin darkening after UV exposure. It brightens and tones sun-damaged and dull-looking skin with dark spots. It boosts collagen synthesis and acts as an antioxidant.</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">Natural Moisturizing Factors</span> - A proprietary blend of Sodium PCA, Provitamin B5, Sodium Hyaluronate, Proline, Hydroxyproline & Glycerin, having superior moisture retention property</p>' +
          '<p><span class="text-customColors-300">Ferulic acid</span> - It neutralizes free radicals and complements the antioxidant benefits of Vitamin-C for visible anti-aging benefits. It increases firmness and replenishes lipids to reduce wrinkles.</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required</p>' +
          '<p>Avoid sun exposure after application</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Contains Clinically-proven Actives for Skin Brightening'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Quick absorbing'
        },
        {
          icon: '',
          value: ''
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Non-sticky & Non-greasy'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ],
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Cleanses gently yet effectively'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Has superior conditioning effect'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Does not over-dry skin'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Free from Animal derivatives'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ]
    ],
    verifiedUser: 0,
    rating: 0,
    reviews: []
  },
  {
    id: 'face-serum-face-wash',
    name: 'Face Serum & Face Wash For Skin Brightening',
    link: 'https://www.vasustore.com/r-g-face-serum-face-wash-for-skin-brightening',
    tag: '',
    size: '(25ml/ 0.84 FL.OZ) (75ml/ 2.54 FL.OZ)',
    price: 664,
    mrp: 948,
    smallDesc:
      'Expertly formulated with the most stable derivative of Vitamin-C, this fast-absorbing face serum is fortified with Niacinamide, Ferulic acid and Natural Moisturizing. Regular exposure to pollution and stress makes our skin look dull and darker.',
    img: '/images/products/face-serum-face-wash.jpg',
    media: [
      { id: 0, value: '/images/products/face-serum-face-wash.jpg' },
      { id: 1, value: '/images/products/1080x1080/serum/01.png' },
      { id: 2, value: '/images/products/1080x1080/serum/03.jpg' },
      { id: 3, value: '/images/products/face-wash.png' },
      { id: 4, value: '/images/products/1080x1080/face-wash/02.jpg' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>A rich combination of <span class="text-customColors-300">clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters,</span> which helps reduce hyper-pigmentation & dark spots and evens out skin tone while protecting from harmful UV radiation, for a visibly brighter and youthful appearance.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Prevent pigmentation</li>' +
          '<li>Protect skin from harmful UV radiation</li>' +
          '<li>To make skin radiant & healthy</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">3-O-Ethyl ascorbic acid</span>- Helps in Skin Lightening and reduces skin darkening after UV exposure. It brightens and tones sun-damaged and dull-looking skin with dark spots. It boosts collagen synthesis and acts as an antioxidant.</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">Natural Moisturizing Factors</span> - A proprietary blend of Sodium PCA, Provitamin B5, Sodium Hyaluronate, Proline, Hydroxyproline & Glycerin, having superior moisture retention property</p>' +
          '<p><span class="text-customColors-300">Ferulic acid</span> - It neutralizes free radicals and complements the antioxidant benefits of Vitamin-C for visible anti-aging benefits. It increases firmness and replenishes lipids to reduce wrinkles.</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required</p>' +
          '<p>Avoid sun exposure after application</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value:
            'Fortified with Natural Moisturizing Factors (NMF) for superior moisturizing effect'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Quick absorbing and fast-acting'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Formulated with the most stable derivative of Vitamin-C'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Light & Non - Greasy'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Animal Cruelty-free'
        }
      ],
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Cleanses gently yet effectively'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Has superior conditioning effect'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Does not over-dry skin'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Free from Animal derivatives'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ]
    ],
    verifiedUser: 0,
    rating: 0,
    reviews: []
  },
  {
    id: 'face-brightening-kit',
    name: 'Face Brightening Kit - Face Serum, Face Cream, Face Wash',
    link: 'https://www.vasustore.com/r-g-face-brightening-kit-face-serum-face-cream-face-wash',
    tag: '',
    size: '(25ml/ 0.84 FL.OZ) (50ml/ 1.69 FL.OZ) (75ml/ 2.54 FL.OZ)',
    price: 1013,
    mrp: 1447,
    smallDesc:
      'Expertly formulated with the most stable derivative of Vitamin-C, this fast-absorbing face serum is fortified with Niacinamide, Ferulic acid and Natural Moisturizing. Regular exposure to pollution and stress makes our skin look dull and darker.',
    img: '/images/products/face-serum-face-cream-face-wash.jpg',
    media: [
      { id: 0, value: '/images/products/face-serum-face-cream-face-wash.jpg' },
      { id: 1, value: '/images/products/1080x1080/serum/01.png' },
      { id: 2, value: '/images/products/1080x1080/cream/01.png' },
      { id: 3, value: '/images/products/face-wash.png' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>A rich combination of <span class="text-customColors-300">clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters,</span> which helps reduce hyper-pigmentation & dark spots and evens out skin tone while protecting from harmful UV radiation, for a visibly brighter and youthful appearance.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Prevent pigmentation</li>' +
          '<li>Protect skin from harmful UV radiation</li>' +
          '<li>To make skin radiant & healthy</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">3-O-Ethyl ascorbic acid</span>- Helps in Skin Lightening and reduces skin darkening after UV exposure. It brightens and tones sun-damaged and dull-looking skin with dark spots. It boosts collagen synthesis and acts as an antioxidant.</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">Natural Moisturizing Factors</span> - A proprietary blend of Sodium PCA, Provitamin B5, Sodium Hyaluronate, Proline, Hydroxyproline & Glycerin, having superior moisture retention property</p>' +
          '<p><span class="text-customColors-300">Ferulic acid</span> - It neutralizes free radicals and complements the antioxidant benefits of Vitamin-C for visible anti-aging benefits. It increases firmness and replenishes lipids to reduce wrinkles.</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required</p>' +
          '<p>Avoid sun exposure after application</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value:
            'Fortified with Natural Moisturizing Factors (NMF) for superior moisturizing effect'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Quick absorbing and fast-acting'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Formulated with the most stable derivative of Vitamin-C'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Light & Non - Greasy'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Animal Cruelty-free'
        }
      ],
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Contains Clinically-proven Actives for Skin Brightening'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Quick absorbing'
        },
        {
          icon: '',
          value: ''
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Non-sticky & Non-greasy'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ],
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Cleanses gently yet effectively'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Has superior conditioning effect'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Does not over-dry skin'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Free from Animal derivatives'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ]
    ],
    verifiedUser: 0,
    rating: 0,
    reviews: []
  },
  {
    id: 'skin-brightening-facial-kit',
    name: 'Skin Brightening Facial Kit For Gold Like Glow - Cleansing Milk, Face Scrub, Facial Massaging Cream, Face Pack - 4 Easy Steps For Bright and Radiant Skin - Usage Upto 7 Times For Facial',
    link: 'https://www.vasustore.com/products/r-g-skin-brightening-facial-kit-for-gold-like-glow-cleansing-milk-face-scrub-facial-massaging-cream-face-pack?variant=43350221717763',
    tag: '',
    size: '(75ml/ 2.54 FL.OZ) (75ml/ 2.54 FL.OZ) (75ml/ 2.54 FL.OZ) (75g/ 2.54 FL.OZ)',
    price: 1199,
    mrp: 1496,
    smallDesc:
      'Expertly formulated with the most stable derivative of Vitamin-C, this fast-absorbing face serum is fortified with Niacinamide, Ferulic acid and Natural Moisturizing. Regular exposure to pollution and stress makes our skin look dull and darker.',
    img: '/images/products/skin-brightening-facial-kit.jpg',
    media: [
      { id: 1, value: '/images/products/1080x1080/skin-brightening-facial-kit/01.jpg' },
      { id: 2, value: '/images/products/1080x1080/skin-brightening-facial-kit/02.jpg' },
      { id: 3, value: '/images/products/1080x1080/skin-brightening-facial-kit/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/skin-brightening-facial-kit/04.jpg' },
      { id: 3, value: '/images/products/1080x1080/skin-brightening-facial-kit/05.jpg' },
      { id: 3, value: '/images/products/1080x1080/skin-brightening-facial-kit/06.jpg' },
      { id: 3, value: '/images/products/1080x1080/skin-brightening-facial-kit/07.png' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>A rich combination of <span class="text-customColors-300">clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters,</span> which helps reduce hyper-pigmentation & dark spots and evens out skin tone while protecting from harmful UV radiation, for a visibly brighter and youthful appearance.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Prevent pigmentation</li>' +
          '<li>Protect skin from harmful UV radiation</li>' +
          '<li>To make skin radiant & healthy</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">3-O-Ethyl ascorbic acid</span>- Helps in Skin Lightening and reduces skin darkening after UV exposure. It brightens and tones sun-damaged and dull-looking skin with dark spots. It boosts collagen synthesis and acts as an antioxidant.</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">Natural Moisturizing Factors</span> - A proprietary blend of Sodium PCA, Provitamin B5, Sodium Hyaluronate, Proline, Hydroxyproline & Glycerin, having superior moisture retention property</p>' +
          '<p><span class="text-customColors-300">Ferulic acid</span> - It neutralizes free radicals and complements the antioxidant benefits of Vitamin-C for visible anti-aging benefits. It increases firmness and replenishes lipids to reduce wrinkles.</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required</p>' +
          '<p>Avoid sun exposure after application</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value:
            'Fortified with Natural Moisturizing Factors (NMF) for superior moisturizing effect'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Quick absorbing and fast-acting'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Formulated with the most stable derivative of Vitamin-C'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Light & Non - Greasy'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Animal Cruelty-free'
        }
      ],
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Contains Clinically-proven Actives for Skin Brightening'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Quick absorbing'
        },
        {
          icon: '',
          value: ''
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Non-sticky & Non-greasy'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ],
      [
        {
          icon: '/images/products/icons/natural-moisturizing.png',
          value: 'Cleanses gently yet effectively'
        },
        {
          icon: '/images/products/icons/quick-absorbing.png',
          value: 'Has superior conditioning effect'
        },
        {
          icon: '/images/products/icons/vitamin-c.png',
          value: 'Does not over-dry skin'
        },
        {
          icon: '/images/products/icons/non-greasy.png',
          value: 'Free from Animal derivatives'
        },
        {
          icon: '/images/products/icons/halal.png',
          value: 'HALAL-certified'
        },
        {
          icon: '/images/products/icons/no-harm-animal.png',
          value: 'Not tested on animals'
        }
      ]
    ],
    verifiedUser: 0,
    rating: 0,
    reviews: []
  }
];
