import React from "react";

export const DropIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="15"
    height="21"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.47943 0.916314C7.36607 0.919543 7.25509 0.949546 7.15554 1.00387C7.056 1.0582 6.97072 1.13531 6.90668 1.2289C6.90668 1.2289 5.49197 3.29795 3.96991 5.7501C2.44786 8.20224 0.823916 10.9518 0.338725 12.7036C-0.218914 14.717 0.650747 16.6338 2.08132 17.9501C3.51189 19.2665 5.51077 20.083 7.50002 20.083C9.48927 20.083 11.4881 19.2665 12.9187 17.9501C14.3493 16.6338 15.219 14.717 14.6613 12.7036C14.1761 10.9518 12.5522 8.20224 11.0301 5.7501C9.50808 3.29795 8.09336 1.2289 8.09336 1.2289C8.02539 1.12956 7.93355 1.04888 7.82628 0.994264C7.71902 0.93965 7.59975 0.912846 7.47943 0.916314ZM7.50002 2.95464C7.91227 3.56488 8.61349 4.58239 9.80882 6.50815C11.3114 8.92889 12.9224 11.8098 13.2762 13.0873C13.6721 14.5168 13.0944 15.8344 11.9454 16.8917C10.7964 17.949 9.08344 18.6455 7.50002 18.6455C5.91661 18.6455 4.20365 17.949 3.05463 16.8917C1.90561 15.8344 1.32791 14.5168 1.72382 13.0873C2.07765 11.8098 3.68866 8.92889 5.19122 6.50815C6.38655 4.58239 7.08778 3.56488 7.50002 2.95464ZM3.89598 12.406C3.7302 12.4089 3.57052 12.4689 3.44396 12.5761C3.31741 12.6832 3.23177 12.8307 3.20156 12.9937C2.92222 14.3905 3.38764 15.5772 4.23008 16.2665C5.07253 16.9558 6.18231 17.208 7.26044 17.208C7.35568 17.2093 7.45024 17.1917 7.53862 17.1562C7.62701 17.1207 7.70745 17.068 7.77528 17.0011C7.84311 16.9342 7.89697 16.8545 7.93373 16.7667C7.97049 16.6788 7.98942 16.5845 7.98942 16.4892C7.98942 16.394 7.97049 16.2997 7.93373 16.2118C7.89697 16.1239 7.84311 16.0442 7.77528 15.9774C7.70745 15.9105 7.62701 15.8578 7.53862 15.8222C7.45024 15.7867 7.35568 15.7691 7.26044 15.7705C6.4219 15.7705 5.61501 15.5435 5.13975 15.1547C4.66449 14.7658 4.41116 14.2755 4.61098 13.2764C4.63383 13.1705 4.63246 13.0608 4.60697 12.9556C4.58148 12.8503 4.53253 12.7521 4.46377 12.6684C4.39502 12.5848 4.30824 12.5177 4.20991 12.4722C4.11159 12.4268 4.00427 12.4042 3.89598 12.406Z"
      fill="#BB8674"
    />
  </svg>
);
