import { useState } from '@hookstate/core';
import React from 'react';
import { skinType } from '../constants';
import TabsDetails from './TabsDetails';

const RoutineTabsSection = () => {
  const isActive = useState(skinType[0].type);
  return (
    <>
      <div className="w-full flex flex-col mt-8">
        <div className="w-full flex flex-row items-center justify-start overflow-hidden overflow-x-auto no-scrollbar">
          <div className="flex-1 xs:flex items-start lg:grid lg:grid-cols-5">
            {skinType.map((nav, index) => (
              <a
                key={index}
                onClick={() => isActive.set(nav.type)}
                className={`cursor-pointer relative pb-3 px-5 uppercase text-base border-b-2 text-center hover:text-customColors-300${
                  isActive.get() === nav.type
                    ? ' font-bold text-customColors-300 border-transparent tb-active'
                    : ' font-normal text-primary border-borderColor'
                }`}
              >
                {nav.name}
              </a>
            ))}
          </div>
        </div>
        <div className="w-full flex xs:flex-col lg:flex-row">
          <TabsDetails skinData={skinType} type={isActive.get()} />
        </div>
      </div>
    </>
  );
};

export default RoutineTabsSection;
