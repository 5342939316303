import React from 'react';

export const CrultyFree = ({ className = '' }) => (
  <svg
    className={className}
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8639 22.9843C22.6576 24.4077 22.2579 29.4428 18.8856 31.5004M19.786 5.95353C19.47 3.77011 19.884 2.0078 20.8979 1.71237C22.1638 1.3435 23.8853 3.40086 24.743 6.30759C25.6008 9.21433 25.27 11.8697 24.0041 12.2386C23.419 12.4091 22.7365 12.0612 22.087 11.3507M18.8856 31.5004C18.0695 31.9983 17.0792 32.3218 15.9322 32.3776C11.1462 32.6103 6.74717 31.0965 5.42302 26.8928M18.8856 31.5004L25.6446 31.5089C26.2944 31.5097 26.8215 30.9827 26.8207 30.3328V30.3328C26.8198 29.6852 26.2951 29.1605 25.6475 29.1597L24.772 29.1585L26.8109 22.6171C28.8343 22.5362 30.6086 21.5246 31.3516 19.8086C32.4639 17.2401 30.8442 14.0661 27.734 12.7193C25.0187 11.5435 22.065 12.1491 20.6186 14.0308C20.4083 14.3045 20.2298 14.6053 20.0885 14.9314C19.8261 15.5375 19.7157 16.1772 19.7412 16.8187C19.2491 16.1888 17.5585 14.9282 14.7327 14.9247C11.5058 14.9206 6.31536 16.4896 5.22444 21.9105M5.22444 21.9105C4.97624 21.8335 4.71248 21.7919 4.4391 21.7915C2.976 21.7897 1.79143 22.9714 1.79328 24.4311C1.79512 25.8907 2.98269 27.0755 4.44579 27.0773C4.79102 27.0777 5.12073 27.0123 5.42302 26.8928M5.22444 21.9105C5.12125 22.4233 5.05473 22.9705 5.03004 23.5542C4.9766 24.817 5.11849 25.926 5.42302 26.8928M20.668 7.35378C22.1802 9.98298 22.4786 12.6438 21.3344 13.2968C20.1902 13.9499 18.0368 12.3479 16.5245 9.71874C15.0122 7.08953 14.7138 4.42873 15.858 3.77567C17.0022 3.1226 19.1557 4.72458 20.668 7.35378ZM27.325 16.3074C27.3254 16.5951 27.0919 16.828 26.8036 16.8276C26.5152 16.8273 26.2812 16.5938 26.2808 16.3061C26.2804 16.0184 26.5139 15.7855 26.8023 15.7859C27.0906 15.7862 27.3247 16.0197 27.325 16.3074Z"
      stroke="url(#paint0_linear)"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="9.47677"
        y1="21.1177"
        x2="32.0529"
        y2="24.962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
