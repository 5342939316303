import React from "react";

export const ExternalUseIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8719 1.92971C9.36978 3.7168 8.73751 6.69842 9.07666 7.57151C9.13029 7.70959 9.40267 8.03784 9.63885 7.92288C10.0046 7.74484 9.89652 6.99523 9.89652 5.3696C9.89652 3.81822 10.5 2.54847 10.8719 1.92971ZM10.8719 1.92971L11.4902 1.10645M0.5 10.8945V12.8938H19.5V10.8945C15.8776 9.08332 14.5094 8.52577 11.3024 8.16983L11.2591 8.167C11.2591 8.99543 10.3284 9.69352 9.5 9.69352C8.67157 9.69352 7.67548 9.02195 7.67548 8.19352V8.16983C3.75341 8.9332 2.38502 10.0863 0.5 10.8945Z"
      stroke="url(#paint0_linear)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.85036"
        y1="9.43649"
        x2="18.7936"
        y2="2.66773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
