import React from 'react';
import Slider from 'react-slick';
import { instaArray } from '../../components/constants';

interface IBeautyImageSlider {
  runSlider?: boolean;
}

const BeautyImageSlider = (props: IBeautyImageSlider) => {
  const {} = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          autoplaySpeed: 3000,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          speed: 500,
          autoplaySpeed: 3000,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="w-full">
        <div className="block relative beauty-slider overflow-hidden">
          <Slider {...settings} className="w-full">
            {instaArray.map((item, i) => (
              <div key={i} className="px-2.5">
                <div
                  className={
                    `${i === 2 || i === 4 || i === 8 ? ' flex items-end' : ' '}` +
                    `${i === 3 || i === 6 || i === 9 ? ' flex items-center' : ' '}`
                  }
                >
                  <a
                    href={item.link}
                    target="_blank"
                    className="w-full h-full overflow-hidden outline-none ring-0 shadow-none focus:outline-none focus:ring-0 focus:shadow-none"
                  >
                    <img src={item.url} className="w-full h-full object-contain" />
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* <div className="xs:block lg:hidden relative beauty-slider overflow-hidden">
          <Slider {...settingsM} className="h-full w-full">
            {instaArray.map((item, i) => (
              <div key={i}>
                <div
                  className={
                    `h-full ${i === 2 || i === 4 || i === 8 ? ' flex items-end' : ' '}` +
                    `${i === 3 || i === 6 || i === 9 ? ' flex items-center' : ' '}`
                  }
                >
                  <a href={item.link} target="_blank" className="w-full h-full overflow-hidden">
                    <img src={item.url} className="w-full h-full object-contain" />
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div> */}
      </div>
    </>
  );
};

export default BeautyImageSlider;
