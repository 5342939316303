import React from "react";

export const CreamVector = ({ className = "" }) => (
  <svg
    className={className}
    width="37"
    height="34"
    viewBox="0 0 37 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5704 9.53933C35.685 9.53933 35.9045 9.39084 36 9.3166V3.08022C35.3126 1.47658 23.685 1.02617 17.957 1.00142C5.48483 0.947574 0.449871 2.43653 1.04725 3.00602C1.04725 5.03832 1.04725 9.12593 1.04725 9.21796C1.04725 9.30999 1.34309 9.48637 1.49101 9.56306M35.5704 9.53933C35.4272 9.53933 35.9284 9.68781 35.9284 9.98478C35.9284 10.2224 35.9284 22.7545 35.9284 28.9909C35.9284 29.1889 35.7995 29.6888 35.284 30.1045C34.2577 31.2182 29.5561 33 18.8162 33C13.6134 32.9753 2.8353 32.2279 1.34604 29.4364L1.27444 29.2136V25.353V10.2533L1.49101 9.6781V9.56306M35.5704 9.53933C27.7837 12.2089 6.03953 11.2886 1.49101 9.56306"
      stroke="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="9.01383"
        y1="23.6142"
        x2="37.6451"
        y2="14.183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
