// import { useState } from '@hookstate/core';
import React, { useState } from 'react';
import VideoModal from '../../../components/Shared/VideoModal';
import InfluencersSlider from './InfluencersSlider';

interface IInfluencersSection {
  isInner?: boolean;
}

const InfluencersSection = (props: IInfluencersSection) => {
  const { isInner } = props;
  const [isRedirectModal, setRedirectModal] = useState(false);
  const [src, setSrc] = useState('');
  const [link, setLink] = useState('');
  // const runSlider = useState(false);
  return (
    <>
      <section id="beauty">
        <div className="relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <div className="w-full flex items-center justify-start">
            <p className=" xs:text-4xl lg:text-6xl font-breadley-sans text-customColors-300">
              R&G INFLUENCERS
            </p>
          </div>
        </div>
        <div className={`w-full flex pb-4${isInner ? ' lg:pt-7 lg:pb-15' : ''}`}>
          <InfluencersSlider
            setSrc={setSrc}
            setLink={setLink}
            isRedirectModal={isRedirectModal}
            setRedirectModal={setRedirectModal}
          />
        </div>

        {isRedirectModal && (
          <VideoModal
            setRedirectModal={setRedirectModal}
            setSrc={setSrc}
            isOpen={isRedirectModal}
            src={src}
            link={link}
          />
        )}
      </section>
    </>
  );
};

export default InfluencersSection;
