// import { useState } from '@hookstate/core';
import React from 'react';
import BeautyImageSlider from './BeautyImageSlider';

interface IBeautySection {
  isInner?: boolean;
}

const BeautySection = (props: IBeautySection) => {
  const { isInner } = props;
  // const runSlider = useState(false);
  return (
    <>
      <section id="beauty">
        <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <div className="w-full flex items-center justify-start">
            <a
              target="_blank"
              href="https://www.instagram.com/roseandgold_india/"
              // onClick={() => runSlider.set(!runSlider.get())}
              className="cursor-pointer xs:text-4xl lg:text-6xl font-breadley-sans text-customColors-300"
            >
              @roseandgold_india
            </a>
          </div>
        </div>
        <div className={`w-full flex pb-4${isInner ? ' lg:pt-7 lg:pb-15' : ''}`}>
          <BeautyImageSlider />
        </div>
      </section>
    </>
  );
};

export default BeautySection;
