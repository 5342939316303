import React from 'react';

export const CodIcon = ({ className = '' }) => (
  <svg
    className={className}
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 19.9104L5.19649 12.9825C6.4269 10.9513 8.34004 9.42421 10.5935 8.67466L16.0769 6.85075M4.23077 26H5.89633C8.51051 26 11.0208 24.9763 12.8894 23.1482L21.0865 15.129C21.7068 14.5221 21.7659 13.5438 21.2231 12.8667L20.8049 12.3451C20.5588 12.0382 20.1335 11.9413 19.7788 12.1115V12.1115M11.0513 16.2985L19.7788 12.1115M16.0769 12.9403V3C16.0769 1.89543 16.9724 1 18.0769 1H27C28.1046 1 29 1.89543 29 3V24C29 25.1046 28.1046 26 27 26H18.0769C16.9724 26 16.0769 25.1046 16.0769 24V20.7761M19.7788 12.1115C20.1243 10.8661 21.2291 9.95522 22.5385 9.95522C24.1245 9.95522 25.4103 11.2917 25.4103 12.9403C25.4103 14.5889 24.1245 15.9254 22.5385 15.9254C22.0154 15.9254 21.525 15.78 21.1026 15.526"
      stroke="url(#paint0_linear)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.41106"
        y1="18.6673"
        x2="30.2077"
        y2="10.9779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
