// import Fade from "react-animation-components";
import _ from 'lodash';
import React from 'react';
import { CreamVector } from '../../../static/svg/CreamVector';
import { DropIcon } from '../../../static/svg/DropIcon';
import { FaceWashVector } from '../../../static/svg/FaceWashVector';
import { SerumVector } from '../../../static/svg/SerumVector';
import { skinDataType } from '../libs/typeInterface';

interface ITabsDetails {
  skinData: skinDataType[];
  type: string;
}

const TabsDetails = (props: ITabsDetails) => {
  const { skinData, type } = props;

  const filteredData = skinData.filter((item) => item.type.includes(type));

  return (
    // <Fade in duration={300}>
    <div className="w-full flex xs:flex-wrap lg:flex-row lg:flex-nowrap justify-start my-5">
      <div className="xs:w-full lg:w-1/2 flex flex-col justify-start lg:pr-8 xs:order-1 lg:order-0">
        <p className="xs:text-3xl lg:text-5xl font-breadley-sans text-customColors-300">
          {filteredData[0].name} Regime
        </p>
        <p className="text-base xs:my-3 lg:my-5 leading-relaxed">{filteredData[0].description}</p>
        <div className="xs:w-full lg:w-9/12 flex flex-col">
          <div className="w-full flex flex-row justify-start uppercase text-customColors-300">
            <p className="w-1/6 mr-1 py-2 text-center">R&G</p>
            <div className="flex-1 flex flex-nowrap justify-center">
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-b border-borderColor text-center">
                Morning(AM)
              </p>
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-b border-borderColor text-center">
                Night(PM)
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-start uppercase text-customColors-300 py-4">
            <div className="w-1/6 mr-1 text-center flex flex-col items-center justify-center">
              <FaceWashVector className="xs:w-6 xs:h-7" />
              <p className="text-customColors-300 text-xs capitalize">Facewash</p>
            </div>
            <div className="flex-1 flex flex-nowrap justify-center">
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-borderColor text-center flex flex-row items-center justify-center space-x-3">
                {filteredData[0].washDrops.morning > 0
                  ? _.times(filteredData[0].washDrops.morning, (i) => <DropIcon key={i} />)
                  : 'No'}
              </p>
              <div
                className="flex bg-borderColor -my-1"
                style={{ width: '1px', height: 'calc(100% + 32px)' }}
              />
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-borderColor text-center flex flex-row items-center justify-center space-x-3">
                {filteredData[0].washDrops.night > 0
                  ? _.times(filteredData[0].washDrops.night, (i) => <DropIcon key={i} />)
                  : 'No'}
              </p>
            </div>
          </div>

          <div className="w-full flex flex-row justify-start uppercase text-customColors-300 py-4">
            <div className="w-1/6 mr-1 text-center flex flex-col items-center justify-center">
              <SerumVector className="xs:w-6 xs:h-7" />
              <p className="text-customColors-300 text-xs capitalize">Serum</p>
            </div>
            <div className="flex-1 flex flex-nowrap justify-center">
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-borderColor text-center flex flex-row items-center justify-center space-x-3">
                {filteredData[0].serumDrops.morning > 0
                  ? _.times(filteredData[0].serumDrops.morning, (i) => <DropIcon key={i} />)
                  : 'No'}
              </p>
              <div
                className="flex bg-borderColor -my-1"
                style={{ width: '1px', height: 'calc(100% + 32px)' }}
              />
              <div className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-borderColor text-center flex flex-col items-center justify-center space-y-2">
                <p className="w-full flex flex-row items-center justify-center space-x-3">
                  {filteredData[0].serumDrops.night > 0
                    ? _.times(filteredData[0].serumDrops.night, (i) => <DropIcon key={i} />)
                    : 'No'}
                </p>
                <p className="w-full xs:px-4 lg:px-0 text-xs">(Before go to bed)</p>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-row justify-start uppercase text-customColors-300 py-4">
            <div className="w-1/6 mr-1 text-center flex flex-col items-center justify-center">
              <CreamVector className="xs:w-6 xs:h-7" />
              <p className="text-customColors-300 text-xs capitalize">Cream</p>
            </div>
            <div className="flex-1 flex flex-nowrap justify-center">
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-borderColor text-center flex flex-row items-center justify-center space-x-3">
                {filteredData[0].creamDrops.morning > 0
                  ? _.times(filteredData[0].creamDrops.morning, (i) => <DropIcon key={i} />)
                  : 'No'}
              </p>
              <div
                className="flex bg-borderColor -my-1"
                style={{ width: '1px', height: 'calc(100% + 32px)' }}
              />
              <p className="flex-1 lg:mx-1.5 lg:px-1.5 py-2 border-borderColor text-center flex flex-row items-center justify-center space-x-3">
                {filteredData[0].creamDrops.night > 0
                  ? _.times(filteredData[0].creamDrops.night, (i) => <DropIcon key={i} />)
                  : 'No'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="xs:w-full lg:w-1/2 flex items-start mt-4 justify-center xs:order-0 xs:mb-10 lg:mb-0 lg:order-1">
        <div className="xs:ml-10 lg:ml-0 w-3/5 flex items-center justify-center relative">
          <div className="w-full h-full rounded-full overflow-hidden">
            <img
              src={filteredData[0].mainImage}
              className="w-full h-full object-contain"
              alt={filteredData[0].type}
            />
          </div>
          <div className="absolute xs:-ml-10 -mb-8 lg:-ml-20 left-0 bottom-0 xs:w-24 lg:h-24 lg:w-40 lg:h-40 rounded-full overflow-hidden">
            <img
              src={filteredData[0].thumbImage}
              className="w-full h-full object-contain"
              alt={filteredData[0].type}
            />
          </div>
        </div>
      </div>
    </div>
    // </Fade>
  );
};

export default TabsDetails;
