import React from "react";

export const FaceWashVector = ({ className = "" }) => (
  <svg
    className={className}
    width="27"
    height="52"
    viewBox="0 0 27 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3337 42.8012C20.0971 42.8838 20.4553 42.3196 20.539 42.0271L20.8906 39.3954L23.1004 18.4963L25.4107 3.68636H25.5614C25.5614 3.04993 25.5614 1.69449 25.5614 1.36423C25.5614 1.03398 25.2266 0.985815 25.0592 1.00302C17.3917 1.00302 1.94628 1.00302 1.50431 1.00302C1.06235 1.00302 0.985338 1.24383 1.00208 1.36423V3.73796L1.20298 3.73797C1.20298 3.70356 1.38378 4.84226 2.107 9.46586C2.83022 14.0895 3.91505 22.4697 4.36706 26.0819C4.8693 30.967 5.9039 40.9641 6.02444 41.8723C6.14498 42.7805 6.84476 42.9044 7.17958 42.8528V42.9044C7.1394 42.9044 7.12936 42.9388 7.12936 42.956V48.6323C7.16954 50.3661 8.15057 50.9028 8.63607 50.9544C11.2644 50.9888 16.8024 51.037 17.9274 50.9544C19.0524 50.8718 19.4006 49.7159 19.4341 49.1483C19.4341 47.1358 19.4341 43.0798 19.4341 42.956C19.4341 42.8322 19.3672 42.8012 19.3337 42.8012ZM19.3337 42.8012L7.24992 42.8414"
      stroke="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.62374"
        y1="36.3347"
        x2="28.419"
        y2="33.1102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
