import React from "react";

export const AwaySunlightIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8206 5.57516C13.9981 5.57516 13.3313 6.24195 13.3313 7.06448C13.3313 7.88701 13.9981 8.5538 14.8206 8.5538C15.387 8.5538 15.8796 8.23763 16.1314 7.77216M14.8206 5.57516C15.6432 5.57516 16.3099 6.24195 16.3099 7.06448C16.3099 7.32062 16.2453 7.56166 16.1314 7.77216M14.8206 5.57516L14.8206 3.70128M13.5135 6.14921L10.5504 5.04845M13.3713 7.40892L6.69818 8.85858M14.3603 8.47759L11.3971 12.4991M15.588 8.47759L16.8579 15.3777M4.38799 15.9851L9.59694 12.8145L14.8206 16.3058M6.69818 17.3816L9.59694 15.3777L12.6734 17.0985M6.69818 17.3816L9.59694 19.0802M6.69818 17.3816L6.69818 20.3875L9.59694 21.9111M9.59694 19.0802L12.6734 17.0985M9.59694 19.0802L9.59694 21.9111M12.6734 17.0985V19.7696L9.59694 21.9111M16.1314 7.77216L19.9728 10.3859M16.3099 6.14921L18.7379 5.04845"
      stroke="url(#paint0_linear)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="10.0348"
        y1="17.4553"
        x2="15.1638"
        y2="2.53727"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
