import React from 'react';
import ReactModal from 'react-modal';

interface IVideoModal {
  setRedirectModal: (x: boolean) => void;
  isOpen: boolean;
  setSrc: (x: string) => void;
  src: string;
  link: string;
}

const VideoModal = (props: IVideoModal) => {
  const { setRedirectModal, isOpen, setSrc, src, link } = props;

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          setRedirectModal(false);
          document.getElementsByTagName('body')[0].removeAttribute('style');
          setSrc('');
        }}
        className="cs-Modal-3 border-none focus:border-none outline-none focus:outline-none"
        overlayClassName="cs-Overlay-2 bg-black bg-opacity-70"
      >
        <div className="w-full flex h-full relative">
          <div className="absolute bg-black bg-opacity-30 top-0 left-0 w-full z-options flex items-center justify-between p-2">
            <a
              href={link}
              target="_blank"
              className="relative px-2 py-px text-customColors-300 lg:hover:text-white default-a after:bg-customColors-300 reverse overflow-hidden z-10"
            >
              Visit Page
            </a>

            <a
              onClick={() => {
                setRedirectModal(false);
                document.getElementsByTagName('body')[0].removeAttribute('style');
                setSrc('');
              }}
              className="cursor-pointer w-6 h-6 rounded-full border-2 border-white text-white text-sm font-roboto flex items-center justify-center"
            >
              x
            </a>
          </div>
          <div className="relative w-full h-full bg-gradientBlack1 text-white shadow-lg z-modal overflow-hidden border-none focus:border-none xs:h-9v lg:h-9.5v">
            <video autoPlay controls playsInline className="w-full h-full object-contain">
              <source src={src} type="video/mp4" />
            </video>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default VideoModal;
