import React from "react";

export const SerumVector = ({ className = "" }) => (
  <svg
    className={className}
    width="18"
    height="54"
    viewBox="0 0 18 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3573 19.2701C13.9994 19.1712 14.1188 18.9816 14.0982 18.8992V11.5435C13.8019 11.1479 12.7811 11.0902 12.3077 11.1108C11.6903 10.1836 11.6286 7.77295 11.5668 5.11501C11.5051 2.45706 10.9494 1.6535 10.4555 1.40625C9.9616 1.159 8.78853 0.664499 7.55372 1.34444C6.31891 2.02438 6.25718 4.55869 6.31892 6.78395C6.36831 8.56415 5.88673 10.2455 5.63977 10.8636L5.55192 11.0348C5.03539 11.0671 3.94422 11.2093 3.71178 11.5196V18.9857C3.77635 19.0503 4.06044 19.199 4.68027 19.2766M13.3573 19.2701C13.3367 19.847 13.3326 21.0997 13.4808 21.4953C13.5425 21.7632 13.8265 22.3854 14.4686 22.7316C15.1107 23.0777 15.8064 23.7412 16.0739 24.0297C16.3003 24.2975 16.7777 25.0681 16.8765 26.0077C16.9753 26.9472 17 42.3468 17 49.9291V50.3618C16.8559 50.9388 16.0369 52.2038 13.913 52.6489C11.2581 53.2052 5.57803 53.0198 4.158 52.6489C3.17016 52.4016 1.16977 51.561 1.07098 50.1764V48.0748L1 26.8397C1.03228 26.4195 1.1937 25.3658 1.5811 24.5126C2.06534 23.446 3.71178 22.4764 4.19603 22.0885C4.58342 21.7782 4.68027 20.9896 4.68027 20.6341V19.2766M13.3573 19.2701C11.8187 19.5436 7.92925 19.9277 4.68027 19.2766"
      stroke="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.66347"
        y1="37.7481"
        x2="19.0487"
        y2="36.415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
