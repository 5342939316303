import React from 'react';

export const FreeShipIcon = ({ className = '' }) => (
  <svg
    className={className}
    width="58"
    height="43"
    viewBox="0 0 48 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.84 0.639648C1.72875 0.639648 0 2.3684 0 4.47965V24.6396C0 26.7509 1.72875 28.4796 3.84 28.4796H6.81C7.26 30.6621 9.21 32.3196 11.52 32.3196C13.83 32.3196 15.78 30.6621 16.23 28.4796H34.65C35.1 30.6621 37.05 32.3196 39.36 32.3196C41.67 32.3196 43.62 30.6621 44.07 28.4796H45.12C46.6987 28.4796 48 27.1784 48 25.5996V19.2696C48 18.1896 47.6213 17.1171 46.95 16.2696L44.28 12.9096L44.25 12.8196L39.36 3.24965C38.5425 1.6409 36.9037 0.639648 35.1 0.639648H3.84ZM3.84 2.55965H29.01L31.38 12.0096C31.68 13.2059 32.7 14.1021 33.93 14.1996L43.47 14.9496L45.45 17.4696C45.8512 17.9759 46.08 18.6209 46.08 19.2696V25.5996C46.08 26.1434 45.6637 26.5596 45.12 26.5596H44.07C43.62 24.3771 41.67 22.7196 39.36 22.7196C37.05 22.7196 35.1 24.3771 34.65 26.5596H16.23C15.78 24.3771 13.83 22.7196 11.52 22.7196C9.21 22.7196 7.26 24.3771 6.81 26.5596H3.84C2.7675 26.5596 1.92 25.7121 1.92 24.6396V4.47965C1.92 3.40715 2.7675 2.55965 3.84 2.55965ZM30.99 2.55965H35.1C36.1838 2.55965 37.1587 3.15215 37.65 4.11965L42.12 12.9096L34.08 12.2796C33.6637 12.2459 33.3413 11.9646 33.24 11.5596L30.99 2.55965ZM11.52 24.6396C13.0387 24.6396 14.2463 25.7984 14.37 27.2796C14.37 27.2909 14.37 27.2984 14.37 27.3096C14.3437 27.4371 14.3437 27.5721 14.37 27.6996C14.37 27.7109 14.37 27.7184 14.37 27.7296C14.2575 29.2259 13.0463 30.3996 11.52 30.3996C9.94875 30.3996 8.68875 29.1659 8.64 27.6096C8.6475 27.5384 8.6475 27.4709 8.64 27.3996C8.70375 25.8546 9.96 24.6396 11.52 24.6396ZM39.36 24.6396C40.9613 24.6396 42.24 25.9184 42.24 27.5196C42.2362 27.5609 42.2362 27.5984 42.24 27.6396C42.1763 29.1846 40.92 30.3996 39.36 30.3996C37.7887 30.3996 36.5287 29.1659 36.48 27.6096C36.4875 27.5384 36.4875 27.4709 36.48 27.3996C36.5438 25.8546 37.8 24.6396 39.36 24.6396Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="10.9904"
        y1="23.0277"
        x2="47.0155"
        y2="6.58894"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
