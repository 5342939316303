import { useState } from '@hookstate/core';
import React from 'react';
import { tabsType } from '../libs/typeInterface';

interface IProductFeatures {
  tabs: tabsType[];
  link?: string;
  isInner?: boolean;
}

const ProductFeatures = (props: IProductFeatures) => {
  const { tabs, link, isInner } = props;
  const activeTab = useState(tabs[0].name);
  return (
    <>
      <div
        className={`w-full border-b-2 border-borderColor flex${
          isInner ? ' xs:justify-between lg:space-x-5 lg:justify-start' : ' justify-between'
        }`}
      >
        {tabs.map((tab, index) => {
          return (
            <a
              key={index}
              onClick={() => activeTab.set(tab.name)}
              className={`xs:text-base lg:text-lg relative cursor-pointer py-2 px-4 capitalize ${
                activeTab.get() === tab.name
                  ? ' text-customColors-300 tb-active'
                  : ' text-secondary'
              }`}
            >
              {tab.name}
            </a>
          );
        })}
      </div>
      <div className="relative w-full flex my-4" style={!isInner ? { height: '120px' } : {}}>
        {tabs.map((tab, index) => {
          return (
            activeTab.get() === tab.name && (
              <div key={index}>
                <div
                  className={`w-full inline-block relative${
                    !isInner ? ' truncate line-clamp-4 whitespace-pre-wrap' : ''
                  }`}
                >
                  {/* {isInner ? (
                    <div dangerouslySetInnerHTML={{ __html: tab.value }} className="space-y-3" />
                  ) : (
                    tab.value
                  )} */}
                  <div dangerouslySetInnerHTML={{ __html: tab.value }} className="space-y-3" />
                </div>
                {!isInner && link && tab.name !== 'usage' && (
                  <a
                    href={link}
                    className="absolute bottom-0 right-0 pl-3 cursor-pointer text-customColors-300 inline-flex"
                  >
                    Read More
                  </a>
                )}
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default ProductFeatures;
