import React from 'react';

export const SatisfactionIcon = ({ className = '' }) => (
  <svg
    className={className}
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10.5L13.9574 13.0901C14.3477 13.4075 14.913 13.3856 15.2776 13.0389L27 1M22.5 2.62207C21.3071 1.60891 19.7761 1 18.1064 1C16.0819 1 14.2612 1.89523 13 3.32083C11.7388 1.89523 9.91814 1 7.89362 1C4.08638 1 1 4.16599 1 8.07143C1 9.49571 1.59462 10.91 2.11018 11.8179C4.22386 15.0245 10.2508 20.6087 13 23C15.7492 20.6087 21.7761 15.0245 23.8898 11.8179C24.4054 10.91 25 9.49571 25 8.07143C25 7.40611 24.9104 6.76225 24.743 6.15185"
      stroke="url(#paint0_linear)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.95312"
        y1="7.45275"
        x2="27.8789"
        y2="14.9007"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
