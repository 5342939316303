import { AutoPlay } from '@egjs/flicking-plugins';
import Flicking from '@egjs/react-flicking';
import React from 'react';
import { PlayCircle } from 'react-feather';
import { InfluencersArray } from '../../../components/constants';
import { influencerType } from '../../../components/libs/typeInterface';

interface IInfluencersSlider {
  runSlider?: boolean;
  setSrc: (x: string) => void;
  setLink: (x: string) => void;
  isRedirectModal: boolean;
  setRedirectModal: (x: boolean) => void;
}
const InfluencersSlider = (props: IInfluencersSlider) => {
  const [isHover, setHover] = React.useState('');
  const { setSrc, setLink, setRedirectModal, isRedirectModal } = props;

  function openVideo(link: string, src: string) {
    if (!isRedirectModal) {
      setRedirectModal(true);
      setSrc(src);
      setLink(link);
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      setRedirectModal(false);
      setSrc('');
      setLink('');
      document.getElementsByTagName('body')[0].removeAttribute('style');
    }
  }
  const plugins = [new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true })];
  return (
    <>
      <div className="w-full">
        <div className="xs:hidden lg:block relative influencer-slider overflow-hidden">
          <Flicking
            circular
            align="prev"
            // plugins={runSlider ? plugins : undefined}
            plugins={plugins}
            inputType={['pointer']}
            moveType="snap"
            threshold={5000}
            duration={8000}
          >
            {InfluencersArray.map((item: influencerType, index: number) => (
              <div
                key={index}
                className="xs:w-1/2 md:w-1/3 lg:w-1/6 flex items-center justify-center relative"
              >
                {item.type === 'video' ? (
                  <a
                    onClick={() => openVideo(item.link, item.url)}
                    className="cursor-pointer flex-1 flex justify-center items-center h-full overflow-hidden mx-2 relative"
                  >
                    {isHover !== item.id.toString() && (
                      <PlayCircle className="absolute text-white w-10 h-10" />
                    )}
                    <video
                      onMouseOver={(event) => {
                        // @ts-ignore
                        event.target.play();
                        setHover(item.id.toString());
                      }}
                      onMouseOut={(event) => {
                        // @ts-ignore
                        event.target.pause();
                        setHover('');
                      }}
                      controls={false}
                      muted
                      loop
                      className="w-full h-full object-cover"
                    >
                      <source src={item.url} type="video/mp4" />
                    </video>
                  </a>
                ) : (
                  <a href="#" target="_blank" className="w-full h-full overflow-hidden">
                    <img src="/img/home/Man2.png" className="w-full h-full object-cover" />
                  </a>
                )}
              </div>
            ))}
          </Flicking>
        </div>

        <div className="xs:block lg:hidden relative influencer-slider overflow-hidden">
          <Flicking
            circular
            align="prev"
            // plugins={runSlider ? plugins : undefined}
            plugins={plugins}
            inputType={['touch']}
            moveType="snap"
          >
            {InfluencersArray.map((item: influencerType, index: number) => (
              <div
                key={index}
                className="xs:w-1/2 md:w-1/3 lg:w-1/6 flex items-center justify-center relative"
              >
                {item.type === 'video' ? (
                  <a
                    onClick={() => openVideo(item.link, item.url)}
                    className="cursor-pointer flex-1 flex justify-center items-center h-full overflow-hidden mx-2 relative"
                  >
                    {isHover !== item.id.toString() && (
                      <PlayCircle className="absolute text-white w-10 h-10" />
                    )}
                    <video
                      playsInline={false}
                      controls={false}
                      muted
                      loop
                      autoPlay
                      className="w-full h-full object-cover"
                    >
                      <source src={item.url} type="video/mp4" />
                    </video>
                  </a>
                ) : (
                  <a href="#" target="_blank" className="w-full h-full overflow-hidden">
                    <img src="/img/home/Man2.png" className="w-full h-full object-cover" />
                  </a>
                )}
              </div>
            ))}
          </Flicking>
        </div>
      </div>
    </>
  );
};

export default InfluencersSlider;
