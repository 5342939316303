import { State } from '@hookstate/core';
import React from 'react';
import ReactModal from 'react-modal';
import { ProtectedIcon } from '../../../static/svg/ProtectedIcon';

interface IClickModal {
  openRedirectModal: State<boolean>;
  isOpen: boolean;
}

const ClickModal = (props: IClickModal) => {
  const { openRedirectModal, isOpen } = props;

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          openRedirectModal.set(false);
          document.getElementsByTagName('body')[0].removeAttribute('style');
        }}
        className="cs-Modal-3 border-none focus:border-none"
        overlayClassName="cs-Overlay-2 bg-black bg-opacity-60"
      >
        <div className="relative w-full lg:max-w-7xl bg-white shadow-lg z-modal overflow-hidden overflow-y-auto border-none focus:border-none xs:max-h-9v lg:max-h-9.5v rounded-xl">
          <div className="w-full flex flex-col xs:p-5 lg:px-10 lg:py-14 relative">
            <p className="absolute top-0 left-0 w-full h-full z-1 bg-leaf bg-no-repeat bg-contain">
              &nbsp;
            </p>
            <div className="w-full flex flex-col items-center">
              <img src="/images/R-&-G-Logo-v2.png" className="w-40" alt="logo" />
              {/* <p className="w-full text-center text-4xl mb-4 font-breadley-sans text-customColors-200 uppercase">
                Disclaimer
              </p>
              <p className="w-full text-center text-base text-primary">
                You are been redirected to Vasu Store for secured checkout.
              </p> */}
              <img src="/images/Vasu-Healthcare-Logo.png" className="w-28 mt-10" alt="logo" />

              <p className="w-full text-center text-base text-primary mt-10">
                Redirecting you to VasuStore.com to complete your purchase
              </p>
            </div>
            <div className="w-full flex items-center justify-center mt-9">
              <ProtectedIcon className="w-10 h-10" />
              <p className="ml-2">Secured Checkout</p>
            </div>
            <div className="w-full flex flex-row items-center justify-center mt-9">
              <div className="dot-elastic" />
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default ClickModal;
