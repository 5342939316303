import React from 'react';
import RoutineTabsSection from './RoutineTabsSection';

const SkinRoutineSection = () => {
  return (
    <section id="skin-routine" className="w-full flex flex-wrap relative bg-customBackground-300">
      <img
        src="/images/skin-routine-bg-img.png"
        className="absolute w-1/2 object-contain bottom-0 right-0 z-auto"
      />
      <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
        <div className="w-full flex items-center flex-col">
          <div className="uppercase text-base relative flex flex-col items-center overflow-hidden">
            <p className="mb-3">TELL US YOUR SKIN TYPE</p>
            <p className="flex w-11/12 bg-golden mb-1" style={{ height: '1px' }} />
          </div>
          <p className="uppercase font-breadley-sans text-customColors-300 xs:text-center xs:text-4xl lg:text-6xl">
            WHAT ROUTINE TO FOLLOW
          </p>
        </div>

        <RoutineTabsSection />
      </div>
    </section>
  );
};

export default SkinRoutineSection;
